import React, { useContext } from "react";
import PropTypes from "prop-types";
import { AnimatePresence, motion } from "framer-motion";
import { CookieContext } from "../Layout";
import Button from "../Button";
import Grid from "../Grid";
import "./style.scss";
import Typography from "../Typography";

const MotionDiv = motion.div;

const CookieConsent = ({ title, text, allow, deny }) => {
  const { cookieConsentAnalytics, cookieConsentTagmanager, cookieConsentFacebookPixel, handleCookieConsent } =
    useContext(CookieContext);
  return (
    <AnimatePresence>
      {cookieConsentAnalytics === null && cookieConsentTagmanager === null && cookieConsentFacebookPixel === null && (
        <MotionDiv
          className="cookie-consent"
          role="alert"
          aria-live="assertive"
          aria-atomic="true"
          key="cookie-consent"
          initial={{ opacity: 0, y: "32px", zIndex: 1060 }}
          animate={{ opacity: 1, y: 0, zIndex: 1060 }}
          exit={{ opacity: 0, y: "32px", zIndex: 1060 }}
          transition={{ duration: 0.2, delay: 0.2, ease: "easeOut", type: "tween" }}
        >
          <Typography variant="paragraph-1" weight="bold" color="gray-1000" className="mb-8">
            {title}
          </Typography>
          <Typography variant="paragraph-2" color="gray-800" className="mb-24">
            {text}
          </Typography>
          <Grid row spacing={16} justify="end">
            <Grid column className="flex-grow-0">
              <Button size="sm" onClick={handleCookieConsent(true)}>
                {allow}
              </Button>
            </Grid>
            <Grid column className="flex-grow-0">
              <Button size="sm" onClick={handleCookieConsent(false)} outline color="light">
                {deny}
              </Button>
            </Grid>
          </Grid>
        </MotionDiv>
      )}
    </AnimatePresence>
  );
};

CookieConsent.propTypes = {
  allow: PropTypes.string,
  deny: PropTypes.string,
  text: PropTypes.string,
  title: PropTypes.string,
};

CookieConsent.defaultProps = {
  allow: "",
  deny: "",
  text: "",
  title: "",
};

export default CookieConsent;
