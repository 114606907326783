import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { useCookie } from "react-use";
import AOS from "aos";
import "aos/dist/aos.css";
import Header from "../Header";
import Footer from "../Footer";
import { siteMetadata } from "../../../gatsby-config";
import CookieConsent from "../CookieConsent";
import "../../scss/main.scss";
import footerNavigation from "../../data/footer.json";
import navigation from "../../data/navigation.json";

export const CookieContext = React.createContext({});

const Layout = ({ children, headerOptions, location, ...rest }) => {
  const urlLanguagePart = (location?.pathname?.split("/") ?? [])[1];
  const language = Object.keys(navigation).includes(urlLanguagePart) ? urlLanguagePart : "en";
  const { cookieConsent } = footerNavigation[language];

  const [cookieConsentAnalytics, setCookieConsentAnalytics, deleteCookieConsentAnalytics] =
    useCookie("gatsby-gdpr-google-analytics");
  const [cookieConsentTagmanager, setCookieConsentTagmanager, deleteCookieConsentTagmanager] = useCookie(
    "gatsby-gdpr-google-tagmanager"
  );
  const [cookieConsentFacebookPixel, setCookieConsentFacebookPixel, deleteCookieConsentFacebookPixel] =
    useCookie("gatsby-gdpr-facebook-pixel");

  const handleCookieConsent = (answer) => () => {
    setCookieConsentAnalytics(answer, { expires: 365 });
    setCookieConsentTagmanager(answer, { expires: 365 });
    setCookieConsentFacebookPixel(answer, { expires: 365 });
    if (answer === true) {
      window.location.reload();
    }
  };

  const handleResetCookieConsent = () => {
    deleteCookieConsentAnalytics();
    deleteCookieConsentTagmanager();
    deleteCookieConsentFacebookPixel();
  };

  useEffect(() => {
    AOS.init();
  }, []);

  return (
    <CookieContext.Provider
      value={{
        cookieConsentAnalytics,
        cookieConsentTagmanager,
        cookieConsentFacebookPixel,
        handleCookieConsent,
        handleResetCookieConsent,
      }}
    >
      <Header title={siteMetadata.title} {...headerOptions} location={location} {...rest} />
      {children}
      <Footer title={siteMetadata.title} author={siteMetadata.author} location={location} />
      <CookieConsent
        title={cookieConsent?.title}
        text={cookieConsent?.text}
        allow={cookieConsent?.buttons?.allow}
        deny={cookieConsent?.buttons?.deny}
      />
    </CookieContext.Provider>
  );
};

Layout.propTypes = {
  children: PropTypes.any,
  headerOptions: PropTypes.object,
  location: PropTypes.object.isRequired,
};

Layout.defaultProps = {
  children: undefined,
  headerOptions: {},
};

export default Layout;
