module.exports = {
  siteMetadata: {
    title: "Linear",
    description: "Linear",
    siteUrl: "https://linear.finance",
    author: "Linear",
    twitter: "Linear",
  },
  pathPrefix: "/",
  plugins: [
    "gatsby-plugin-sharp",
    "gatsby-transformer-sharp",
    {
      resolve: "gatsby-transformer-remark",
      options: {
        plugins: [
          {
            resolve: "gatsby-remark-classes",
            options: {
              classMap: {
                table: "table table-striped mb-32",
              },
            },
          },
          {
            resolve: "gatsby-remark-relative-images",
            options: {
              name: "uploads",
            },
          },
          {
            resolve: "gatsby-remark-images",
            options: {
              // It's important to specify the maxWidth (in pixels) of
              // the content container as this plugin uses this as the
              // base for generating different widths of each image.
              maxWidth: 2048,
            },
          },
          {
            resolve: "gatsby-remark-copy-linked-files",
            options: {
              destinationDir: "static",
            },
          },
          {
            resolve: "gatsby-remark-responsive-iframe",
            options: {
              wrapperStyle: "margin-bottom: 1.0725rem",
            },
          },
          "gatsby-remark-prismjs",
        ],
      },
    },
    {
      // keep as first gatsby-source-filesystem plugin for gatsby image support
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/static/img`,
        name: "uploads",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/pages/blog`,
        name: "blogs",
      },
    },
    {
      resolve: "gatsby-source-filesystem",
      options: {
        path: `${__dirname}/src/pages`,
        name: "pages",
        ignore: [`blog/*`],
      },
    },
    {
      resolve: `gatsby-plugin-remote-images`,
      options: {
        nodeType: "MarkdownRemark",
        imagePath: "nodes[].frontmatter.banner",
      },
    },
    {
      resolve: "gatsby-plugin-gdpr-cookies",
      options: {
        googleAnalytics: {
          trackingId: "UA-188435556-1",
        },
        googleTagManager: {
          trackingId: "G-W3QWFWGDRV",
        },
        environments: ["production"],
      },
    },
    {
      resolve: "gatsby-plugin-netlify",
      options: {
        mergeSecurityHeaders: true,
        mergeLinkHeaders: true,
        mergeCachingHeaders: true,
      },
    },
    {
      resolve: "gatsby-plugin-local-search",
      options: {
        name: "pages",
        engine: "flexsearch",
        query: `
        query {
          allMarkdownRemark(
            filter: { frontmatter: { slug: { regex: "/blog/" } } }
            sort: { fields: frontmatter___date, order: DESC }
          ) {
            nodes {
              frontmatter {
                slug
                title
                date
              }
            }
          }
        }
        `,
        ref: "slug",
        index: ["title", "excerpt"],
        store: ["title", "excerpt", "date", "slug"],
        normalizer: ({ data }) =>
          data.allMarkdownRemark.nodes.map((node) => ({
            title: node.frontmatter.title,
            excerpt: node.excerpt,
            date: node.frontmatter.date,
            slug: node.frontmatter.slug,
          })),
      },
    },
    "gatsby-plugin-fontawesome-css",
    "gatsby-plugin-catch-links",
    {
      resolve: "gatsby-plugin-offline",
      options: {
        workboxConfig: {
          globPatterns: ["**/img/favicon*", "**/img/icon-*"],
        },
      },
    },
    "gatsby-plugin-react-helmet",
    "gatsby-plugin-sass",
    "gatsby-plugin-stylelint",
    {
      resolve: "gatsby-plugin-web-font-loader",
      options: {
        custom: {
          families: ["My Font", "My Other Font:n4,i4,n7"],
          urls: ["/fonts.css"],
        },
      },
    },
    {
      resolve: "gatsby-plugin-react-svg",
      options: {
        rule: {
          include: /images/,
        },
      },
    },
    "gatsby-plugin-sitemap",
    "@bumped-inc/gatsby-plugin-optional-chaining",
    // {
    //   resolve: 'gatsby-plugin-manifest',
    //   options: {
    //     name: 'Linear',
    //     short_name: 'Linear',
    //     description: 'Linear',
    //     homepage_url: 'https://linear.finance',
    //     start_url: '/',
    //     background_color: '#fff',
    //     theme_color: '#005eff',
    //     display: 'standalone',
    //     cache_busting_mode: 'none',
    //     icons: [
    //       {
    //         src: '/img/icon-16x16.png',
    //         sizes: '16x16',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-32x32.png',
    //         sizes: '32x32',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-48x48.png',
    //         sizes: '48x48',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-72x72.png',
    //         sizes: '72x72',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-96x96.png',
    //         sizes: '96x96',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-144x144.png',
    //         sizes: '144x144',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-192x192.png',
    //         sizes: '192x192',
    //         type: 'image/png',
    //         purpose: 'maskable',
    //       },
    //       {
    //         src: '/img/icon-256x256.png',
    //         sizes: '256x256',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-384x384.png',
    //         sizes: '384x384',
    //         type: 'image/png',
    //       },
    //       {
    //         src: '/img/icon-512x512.png',
    //         sizes: '512x512',
    //         type: 'image/png',
    //       },
    //     ],
    //   },
    // },
    {
      resolve: "gatsby-plugin-zopfli",
      options: {
        extensions: ["ico", "ttf"],
      },
    },
    {
      resolve: "gatsby-plugin-netlify-cms",
      options: {
        modulePath: `${__dirname}/src/cms/cms.js`,
      },
    },
    "gatsby-plugin-netlify",
  ],
};
